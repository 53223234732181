import * as Sentry from '@sentry/nextjs';
import Head from 'next/head';
import React from 'react';

interface Props {
  pixelId: string,
}

const TikTokPixel: React.FC<Props> = ({ pixelId }: Props) => {
  // Script for initializing TikTok Pixel
  // See https://ads.tiktok.com/help/article/get-started-pixel?lang=en
  // Remove the <script></script> tags from the script that you copy from the "Base Code" on the side
  const tiktokPixelScript = `
!function (w, d, t) {
  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};


  ttq.load('${pixelId}');
  ttq.page();
}(window, document, 'ttq');
<!-- TikTok Pixel Code End -->
`;

  return (
    <Head>
      {/* eslint-disable-next-line react/no-danger */}
      <script dangerouslySetInnerHTML={{ __html: tiktokPixelScript }} />
      {/* TODO: A noscript tag? */}
    </Head>
  );
};

// See here for example code:
// https://ads.tiktok.com/i18n/events_manager/v2/creationFlow/setup/CQKL4RJC77UE89C5M2J0?type=3&aadvid=7397533684009500689

export const identifyTikTokPixel = ({ email, phone_number, mental_user_id }: { email?: string, phone_number?: string, mental_user_id?: string }) => {
  const props: Record<string, string> = {};
  if (email) props.email = email;
  if (phone_number) props.phone_number = phone_number;
  if (mental_user_id) props.external_id = mental_user_id;

  if (typeof window === 'undefined') return;
  // @ts-ignore
  const { ttq } = window;
  if (!ttq || !ttq.identify) return;

  try {
    ttq.identify(props);
  } catch (e) {
    console.error('Error identifying TikTok pixel', e, props);
  }
};

export type TikTokEventContent = Partial<{
  content_id: string,
  content_type: 'product' | 'product_group',
  content_name: string,
}>;

type TikTokEventParameters = Partial<{
  contents: TikTokEventContent[],
  value: number,
  currency: string,
}>;

// Function to track events with event_id
export const trackTikTokEvent = (eventName: string, parameters?: TikTokEventParameters) => {
  if (typeof window === 'undefined') return;
  // @ts-ignore
  const { ttq } = window;
  if (!ttq || !ttq.track) return;

  try {
    ttq.track(eventName, { ...parameters });
    return true;
  } catch (e) {
    console.error('Error tracking TikTok event', e);
    Sentry.captureException(e, {
      contexts: {
        tik_tok_pixel: {
          eventName,
          parameters,
        },
      },
    });
    return false;
  }
};

export default TikTokPixel;

// // add this before event code to all pages where PII data postback is expected and appropriate
// ttq.identify({
//   "email": "<hashed_email_address>", // string. The email of the customer if available. It must be hashed with SHA-256 on the client side.
//   "phone_number": "<hashed_phone_number>", // string. The phone number of the customer if available. It must be hashed with SHA-256 on the client side.
//   "external_id": "<hashed_extenal_id>" // string. Any unique identifier, such as loyalty membership IDs, user IDs, and external cookie IDs.It must be hashed with SHA-256 on the client side.
// });

// ttq.track('AddToCart', {
//   "contents": [
//     {
//       "content_id": "<content_identifier>", // string. ID of the product. Example: "1077218".
//       "content_type": "<content_type>", // string. Either product or product_group.
//       "content_name": "<content_name>" // string. The name of the page or product. Example: "shirt".
//     }
//   ],
//   "value": "<content_value>", // number. Value of the order or items sold. Example: 100.
//   "currency": "<content_currency>" // string. The 4217 currency code. Example: "USD".
// });
