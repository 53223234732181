import {
  Identify, identify, init, setDeviceId, setUserId, track,
} from '@amplitude/analytics-browser';
import { AnalyticsEventName, AnalyticsProperties, ValidAnalyticsProperties } from 'libs/analytics/types';
import { isInBrowser } from 'libs/browser';
import { LocalLogger } from 'libs/logging';
import { getDeviceIdAndSources, getInitialDeviceIdValues, InitialDeviceIdValues } from 'models/device_id';

const AMPLITUDE_API_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY;
let didInitAmplitude = false;

class MentalAnalytics {
  deviceId: string | undefined;

  deviceIdSources: string[] | undefined;

  initialDeviceIdValues: InitialDeviceIdValues | undefined;

  constructor() {
    if (isInBrowser && AMPLITUDE_API_KEY) {
      didInitAmplitude = true;
      init(AMPLITUDE_API_KEY);

      const deviceIdAndSources = getDeviceIdAndSources();
      this.deviceId = deviceIdAndSources.deviceId;
      this.deviceIdSources = deviceIdAndSources.sources;

      this.initialDeviceIdValues = getInitialDeviceIdValues();

      setDeviceId(this.deviceId);
      this.trackEvent('App : Landed');
      this.trackEvent('Web : Device Id : Tracked', {
        ...this.initialDeviceIdValues,
      });
    }
  }

  trackError(error: any, context: string, properties: AnalyticsProperties) {
    // TODO: Send to bugsnag or similar
  }

  getReferralSourceProps() {
    try {
      const referralUrl = document.referrer;
      let referralSource = 'Other';
      if (referralUrl.includes('facebook.com')) {
        referralSource = 'Facebook';
      } else if (referralUrl.includes('google.com')) {
        referralSource = 'Google';
      } else if (referralUrl === '') {
        referralSource = 'Direct';
      }
      return {
        referral_source: referralSource,
        referral_url: document.referrer,
      };
    } catch (err) {
      return {
        referral_source: 'Unknown',
        referral_url: 'Unknown',
      };
    }
  }

  getUTMProps() {
    const utmProps: AnalyticsProperties = {};
    try {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.forEach((value, key) => {
        if (key.startsWith('utm_')) {
          utmProps[key] = value;
        }
      });
    } catch (err) {
      if (err instanceof Error) {
        utmProps.utm_error = err.toString();
      } else {
        utmProps.utm_error = String(err);
      }
    }
    return utmProps;
  }

  async trackEvent(eventName: AnalyticsEventName, eventProperties?: AnalyticsProperties) {
    if (!didInitAmplitude) return;
    const fullProperties = {
      ...this.cleanProperties(eventProperties),
      ...this.getReferralSourceProps(),
      ...this.getUTMProps(),
      mental_device_id: this.deviceId,
      website_pathname: window.location.pathname,
      website_host: window.location.host,

      // These are removed for security / privacy reasons
      // Otherwise any query params are sent to Amplitude, which is not secure and could include PII or sensitive tokens
      // website_search: window.location.search,
      // website_href: window.location.href,
    };
    LocalLogger(`MentalAnalytics : Track Event \`${eventName}\` with properties ${JSON.stringify(fullProperties, null, 2)}`);
    const trackResult = track(eventName, fullProperties);
    await trackResult?.promise;
  }

  // remove undefineds, and convert Dates to ISO8601 strings
  cleanProperties(properties?: AnalyticsProperties): ValidAnalyticsProperties {
    const cleanedProperties: ValidAnalyticsProperties = {};
    Object.entries(properties ?? {}).forEach((entry) => {
      const [key, value] = entry;
      if (!value) { return; }
      if (value instanceof Date) {
        cleanedProperties[key] = value.toISOString();
        return;
      }
      cleanedProperties[key] = value;
    });
    return cleanedProperties;
  }

  updateUserProperties(userProperties: AnalyticsProperties) {
    if (!didInitAmplitude) return;
    LocalLogger('MentalAnalytics : Update User Properties', JSON.stringify(userProperties, null, 2));
    const event = new Identify();
    const cleanProperties = this.cleanProperties(userProperties);
    Object.entries(cleanProperties).forEach((entry) => {
      const [key, value] = entry;
      event.set(key, value);
    });
    identify(event);
  }

  identifyUser({ id, email }: { id: string, email?: string }) {
    if (!didInitAmplitude) return;
    LocalLogger('MentalAnalytics : Update User', { id, email });

    this.updateUserProperties({ email });

    if (id) {
      setUserId(id);
    }
  }

  // TODO: Call this when user logs out
  resetUser() {
    // if (!didInitAmplitude) return;
    // LocalLogger('Analytics: Inside resetUser');
    // TODO: Is this available in the new TS SDK?
  }

  errorProperties(error: any, includeStack: boolean = false): AnalyticsProperties {
    return {
      error_code: error?.code,
      error_name: error?.name,
      error_message: error?.message,
      error_stack: includeStack ? error?.stack : undefined,
    };
  }
}

export default new MentalAnalytics();
