import MentalButton from 'components/foundation/buttons/MentalButton';
import { logOutUser, useVerifiedUser } from 'models/user';
import { useRouter } from 'next/router';
import React from 'react';
import MentalLettermark from 'resources/svgs/MentalLettermark';
import grid from 'styles/grid';
import pallette from 'styles/pallette';

type HeaderProps = {
  isLogoClickable?: boolean,
  showLogout?: boolean,
  onChangeHeight?: (height: number) => void,
  scrollToTop?: boolean,
};

const Header = ({
  isLogoClickable = true, showLogout = true, onChangeHeight, scrollToTop = false,
}: HeaderProps) => {
  const router = useRouter();
  const lettermarkHeight = 43;
  const paddingVertical = grid.Small;
  const headerHeight = lettermarkHeight + 2 * paddingVertical;

  React.useEffect(() => {
    if (onChangeHeight) {
      onChangeHeight(headerHeight);
    }
  }, [headerHeight]);

  const user = useVerifiedUser();

  const renderLogo = () => {
    if (isLogoClickable) {
      if (scrollToTop) {
        return (
          <button
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            style={{
              cursor: 'pointer',
              background: 'none',
              border: 'none',
              padding: 0,
            }}
          >
            <MentalLettermark height={lettermarkHeight} />
          </button>
        );
      }
      return (
        <a href="/">
          <MentalLettermark height={lettermarkHeight} />
        </a>
      );
    }
    return <MentalLettermark height={lettermarkHeight} />;
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          position: 'fixed',
          width: '100vw',
          zIndex: 999,
          top: 0,
          height: headerHeight,
          backgroundColor: pallette.neutral.w1000,
        }}
      >
        {renderLogo()}
        {showLogout && user?.is_verified && (
          <MentalButton
            role="link"
            style={{ textAlign: 'right' }}
            containerClassName="absolute w-auto p-0 right-0 text-right"
            onPress={() => {
              logOutUser();
              router.push('/login');
            }}
            title="Log out"
          />
        )}
      </div>
      <div style={{ width: '100vw', height: headerHeight }} />
    </>
  );
};
export default Header;
