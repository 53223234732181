import Color from 'color';

const rawPallette = {
  primary: {
    w600: '#24E074',
    w500: '#4EE69F',
    w400: '#6FF2BA',
    w300: '#A5FAD8',
    w200: '#DBFFF1',
    w100: '#F5FFFB',
  },
  secondary: {
    w500: '#EF762B',
    w400: '#E57F40',
    w300: '#E48E59',
  },
  tertiary: {
    w1000: '#1D242F',
    w800: '#364156',
    w600: '#56688A',
    w400: '#8A9BBD',
    w200: '#BAC5D9',
  },
  neutral: {
    w1000: '#040405',
    w900: '#1A1E24',
    w800: '#2C333D',
    w700: '#454C57',
    w600: '#5E6570',
    w500: '#79808A',
    w400: '#9399A3',
    w300: '#AEB4BD',
    w200: '#CBD0D6',
    w100: '#E6EAF0',
  },
  accent: {
    error: '#E64E4E',
    errorPressed: '#EA6A6A',
    link: '#00AAFF',
    linkPressed: '#0077B2',
    transparent: 'transparent',
    innerShadow: '#1D1D1D',

    // typically combined with some transparency
    backgroundOverlay: '#001022',

    cold_showers: {
      hot_gradient: ['#FF4D00', '#FC7D03'],
      cold_gradient: ['#0500FF', '#0094FF'],
      cold: '#00AAFF',
    },
  },

  transparent: (color: string, opacity: number): string => {
    return Color(color).alpha(opacity).toString();
  },

  darken: (color: string, amount: number): string => {
    return Color(color).darken(amount).toString();
  },

  randomColor: (): string => {
    return Color()
      .hue(Math.random() * 255)
      .saturationl(1.0 * 100)
      .lightness(0.5 * 100)
      .toString();
  },
};

const pallette = {
  ...rawPallette,

  brandColor: rawPallette.primary.w500,
  brandButton: rawPallette.primary.w300,

  trueBlack: '#000000',

  clear: rawPallette.transparent('#000000', 0.0),

  lightBg: rawPallette.neutral.w200,
  darkBg: rawPallette.neutral.w900,

  lightText: rawPallette.neutral.w100,
  darkText: rawPallette.tertiary.w1000,

  selectionColor: rawPallette.accent.link,

  transparentCardBackground: rawPallette.transparent(rawPallette.neutral.w1000, 0.80),

  modalBackgroundGradient: [
    rawPallette.neutral.w900,
    rawPallette.neutral.w1000,
  ],

  dropShadow: [
    rawPallette.transparent(rawPallette.tertiary.w1000, 0.0),
    rawPallette.transparent(rawPallette.tertiary.w1000, 1.0),
  ],

  backgroundGradient: [
    rawPallette.tertiary.w1000,
    rawPallette.tertiary.w800,
  ],

  chatBackgroundGradient: [
    rawPallette.tertiary.w600,
    rawPallette.tertiary.w1000,
  ],

  accentGradient: [
    rawPallette.neutral.w1000,
    rawPallette.tertiary.w1000,
  ].map((c) => rawPallette.transparent(c, 0.7)),

  darkHeaderBackgroundGradient: [
    rawPallette.tertiary.w1000,
    rawPallette.neutral.w1000,
  ],
};
export default pallette;
